import { FC, MouseEventHandler, useCallback } from "react"
import styled, { useTheme } from "styled-components"
import Link from "next/link"
import OrangeDot from "@hornet-web-react/core/components/UI/OrangeDot"
import NiceModal from "@ebay/nice-modal-react"
import {
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
  ExclamationTriangleIcon,
  MapIcon,
  MinusCircleIcon,
  EyeSlashIcon as EyeSlashIconOutline,
  UserCircleIcon,
} from "@heroicons/react/24/outline"
import {
  BugAntIcon,
  ChatBubbleLeftRightIcon,
  EyeSlashIcon,
  MapIcon as MapActiveIcon,
  QrCodeIcon,
} from "@heroicons/react/24/solid"
import useTranslation from "next-translate/useTranslation"
import { useRouter } from "next/router"
import { useSessionTotals } from "@hornet-web-react/core/hooks/use-session-totals"
import { useQuickiesSession } from "../../../hooks/use-quickies-session"
import invariant from "tiny-invariant"
import { useQuickiesProfilePreview } from "../../../hooks/use-quickies-profile-preview"
import appConfig from "../../../app-config"
import DebugViewModal from "@hornet-web-react/core/components/DebugViewModal"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { useChatNotificationsState } from "../../../contexts/chat-notifications-context"
import {
  ActionSheetModalItemType,
  QuickiesPremiumPaywall,
} from "@hornet-web-react/core/types"
import { useQuickiesVanillaMode } from "../../../hooks/use-quickies-vanilla-mode"
import QuickiesMemberAvatar from "../../QuickiesMemberAvatar"
import { useQuickiesNavigation } from "../../../hooks/use-quickies-navigation"
import { useEffectOnce } from "react-use"
import { InboxType } from "@hornet-web-react/chat/models/inbox-conversations.model"
import { useQuickiesLogout } from "../../../hooks/use-quickies-logout"
import { useQuickiesPaywall } from "../../../hooks/use-quickies-paywall"
import { useQuickiesPremium } from "../../../hooks/use-quickies-premium"
import { useQuickiesFeatureFlags } from "../../../hooks/use-quickies-feature-flags"
import QuickiesAnalyticsEvent from "../../../models/quickies-analytics-event"
import { useEventTrackerService } from "../../../hooks/use-event-tracker-service"
import { FaUsers } from "react-icons/fa"
import { useQuickiesUserInvites } from "../../../hooks/use-quickies-user-invites"

type NavBarProps = {
  className?: string
  hasNotifications?: boolean
}

const i18nKey = "quickies:components.layout.nav_bar.nav_bar"

const NavBar: FC<NavBarProps> = ({ className }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const { makeApiRequest } = useApi()
  const { data: sessionTotals } = useSessionTotals()
  const { data: quickiesSession, hasActiveQuickiesProfile } =
    useQuickiesSession()
  const { openProfilePreview } = useQuickiesProfilePreview()
  const { isChatNotificationShown } = useChatNotificationsState()
  const { state: isVanillaMode, toggle: toggleVanillaMode } =
    useQuickiesVanillaMode()
  const { navigateToAccount } = useQuickiesNavigation()
  const { logoutAction } = useQuickiesLogout()
  const { isChatPaywalled, isGroupsPaywalled } = useQuickiesPaywall()
  const { openQuickiesPremiumModal, preloadModal } = useQuickiesPremium()
  const { hasProfileDeactivationEnabled, hasGroupsEnabled } =
    useQuickiesFeatureFlags()
  const { reportEvent } = useEventTrackerService()
  const { data: userInvites } = useQuickiesUserInvites()

  useEffectOnce(() => {
    // we know we're going to use it
    void preloadModal()
  })

  const defaultInbox = quickiesSession?.defaultInbox || InboxType.enum.all

  useEffectOnce(() => {
    // preload the modal
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(
        async () =>
          import("@hornet-web-react/core/components-lazy/ActionSheetModal")
      )
    }
  })

  const hasInviteFriendInProfileMenu = useCallback(() => {
    // check for support first
    if (typeof window == "undefined" || !window.matchMedia) {
      return false
    }

    // greater than medium-sized screen
    const mql = window.matchMedia(
      theme.breakpoints.mediaQueryHasVisibleLeftPanel
    )

    return mql.matches && !!userInvites?.quickies_invite.invite_code
  }, [
    theme.breakpoints.mediaQueryHasVisibleLeftPanel,
    userInvites?.quickies_invite.invite_code,
  ])

  const isMapActive = router.route.includes("/map")
  const isChatActive = router.route.includes("/inbox")
  const isGroupsActive = router.route.includes("/groups")

  const hasDebugView =
    appConfig.environment === "alpha" || appConfig.environment === "development"
  const hasSettings = hasProfileDeactivationEnabled || hasDebugView

  const profileSheetModalItems: ActionSheetModalItemType[] = [
    {
      id: "my_profile",
      icon: quickiesSession ? (
        <QuickiesMemberAvatar
          avatarUrl={quickiesSession.profile.profilePhoto?.thumbnailUrl}
          size={24}
          borderRadius={"8px"}
        />
      ) : null,
      label: t(`${i18nKey}.profile_actions.actions.my_profile`),
      isDisabled: !quickiesSession,
    },
  ]

  if (hasInviteFriendInProfileMenu()) {
    profileSheetModalItems.push({
      id: "invite_friend",
      icon: <QrCodeIcon height={24} color={theme.color.tint.default} />,
      label: t(`${i18nKey}.profile_actions.actions.invite_friend`),
    })
  }

  profileSheetModalItems.push({
    id: "account",
    icon: <UserCircleIcon color={theme.color.tint.default} width={24} />,
    label: t(`${i18nKey}.profile_actions.actions.account`),
  })

  if (hasSettings) {
    profileSheetModalItems.push({
      id: "settings",
      icon: <Cog6ToothIcon color={theme.color.tint.default} width={24} />,
      label: t(`${i18nKey}.profile_actions.actions.settings`),
      isDisabled: false,
    })
  }

  profileSheetModalItems.push({
    id: "blocked_users",
    icon: <MinusCircleIcon color={theme.color.tint.default} width={24} />,
    label: t(`${i18nKey}.profile_actions.actions.blocked_users`),
  })

  profileSheetModalItems.push({
    id: "log_out",
    icon: (
      <ArrowRightOnRectangleIcon color={theme.color.tint.default} width={24} />
    ),
    label: t(`${i18nKey}.profile_actions.actions.log_out`),
    isDisabled: !quickiesSession,
  })

  if (hasDebugView) {
    profileSheetModalItems.push({
      id: "debug_view",
      icon: <BugAntIcon color={theme.color.tint.default} width={24} />,
      label: `DebugView (dev/alpha)`,
      isDisabled: false,
    })
  }

  const openBlockedUsersModal = async () => {
    const BlockedMembersListModal = (
      await import(
        "@hornet-web-react/core/components-lazy/BlockedMembersListModal"
      )
    ).default

    void NiceModal.show(BlockedMembersListModal, {
      appConfig,
    })
  }

  const openUserInvitesModal = async () => {
    invariant(userInvites, "User invites must be loaded")

    const UserInvitesModal = (await import("../../Modals/UserInvitesModal"))
      .default

    void NiceModal.show(UserInvitesModal, {
      userInvite: userInvites,
    })
  }

  const showProfileSheetModal = async () => {
    const ActionSheetModal = (
      await import("@hornet-web-react/core/components-lazy/ActionSheetModal")
    ).default

    NiceModal.show(ActionSheetModal, {
      title: t(`${i18nKey}.profile_actions.title`),
      items: profileSheetModalItems,
    }).then(async (value: unknown) => {
      switch (value) {
        case "my_profile":
          invariant(quickiesSession, "quickiesSession is required")
          openProfilePreview(quickiesSession.profile.profileId)
          break

        case "account":
          void navigateToAccount()
          break

        case "settings":
          await new Promise((r) => setTimeout(r, 200))
          void showSettingsSheetModal()
          break

        case "blocked_users":
          void openBlockedUsersModal()
          break

        case "invite_friend":
          void openUserInvitesModal()
          break

        case "debug_view": {
          void NiceModal.show(DebugViewModal)
          break
        }

        case "log_out": {
          invariant(quickiesSession, "quickiesSession is required")

          if (quickiesSession.isAnonymous) {
            // warn about deletion first
            const ConfirmModal = (
              await import(
                "@hornet-web-react/core/components-lazy/ConfirmModal"
              )
            ).default

            NiceModal.show(ConfirmModal, {
              isDangerous: true,
              question: t(`${i18nKey}.profile_actions.anon_logout.question`),
              confirmLabel: t(`${i18nKey}.profile_actions.anon_logout.confirm`),
              cancelLabel: t(`${i18nKey}.profile_actions.anon_logout.cancel`),
            }).then(() => logoutAction())
          } else {
            await logoutAction()
          }

          break
        }
      }
    })
  }
  const settingsSheetModalItems: ActionSheetModalItemType[] = [
    {
      id: "vanilla_mode",
      icon: isVanillaMode ? (
        <EyeSlashIconOutline color={theme.color.tint.default} width={24} />
      ) : (
        <EyeSlashIcon color={theme.color.tint.default} width={24} />
      ),
      label: t(
        `${i18nKey}.settings_actions.actions.turn_censored_mode_${
          isVanillaMode ? "off" : "on"
        }`
      ),
    },
  ]

  if (hasProfileDeactivationEnabled) {
    settingsSheetModalItems.push({
      id: "deactivate",
      icon: (
        <ExclamationTriangleIcon color={theme.color.text.error} width={24} />
      ),
      label: t(`${i18nKey}.settings_actions.actions.deactivate`),
      isDisabled: !quickiesSession,
      isDanger: true,
    })
  }

  const showSettingsSheetModal = async () => {
    const ActionSheetModal = (
      await import("@hornet-web-react/core/components-lazy/ActionSheetModal")
    ).default

    NiceModal.show(ActionSheetModal, {
      title: t(`${i18nKey}.settings_actions.title`),
      items: settingsSheetModalItems,
    }).then(async (value: unknown) => {
      switch (value) {
        case "deactivate":
          {
            const ConfirmModal = (
              await import(
                "@hornet-web-react/core/components-lazy/ConfirmModal"
              )
            ).default

            NiceModal.show(ConfirmModal, {
              isDangerous: true,
              question: t(`${i18nKey}.settings_actions.deactivate.question`),
              confirmLabel: t(`${i18nKey}.settings_actions.deactivate.confirm`),
              cancelLabel: t(`${i18nKey}.settings_actions.deactivate.cancel`),
            }).then(async () => {
              await makeApiRequest(
                ApiService.getEndpoint(
                  ApiServiceEndpoint.QuickiesProfileDeactivatePut
                )
              )

              await logoutAction()
            })
          }
          break

        case "vanilla_mode": {
          void toggleVanillaMode()
          break
        }
      }
    })
  }

  // no navbar if no profile = means some kind of onboarding is required
  if (!hasActiveQuickiesProfile) {
    return null
  }

  const handleChatPaywalledOnClick: MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.stopPropagation()
    event.preventDefault()

    void openQuickiesPremiumModal(QuickiesPremiumPaywall.enum.navbar_chat)
  }

  const handleGroupsPaywalledOnClick: MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.stopPropagation()
    event.preventDefault()

    void openQuickiesPremiumModal(QuickiesPremiumPaywall.enum.navbar_groups)
  }

  return (
    <Items className={className}>
      <Item isActive={isMapActive}>
        <ItemLink
          href="/map"
          aria-label={t(`${i18nKey}.items.map`)}
          onClick={() =>
            void reportEvent(QuickiesAnalyticsEvent.navigationTapOnMap())
          }
        >
          {isMapActive ? (
            <MapActiveIcon height={26} />
          ) : (
            <MapIcon height={26} />
          )}
        </ItemLink>
      </Item>

      <Item isActive={isChatActive}>
        <ItemLink
          href={`/inbox/${defaultInbox}`}
          aria-label={t(`${i18nKey}.items.chat`)}
          onClick={
            isChatPaywalled
              ? handleChatPaywalledOnClick
              : () => {
                  void reportEvent(
                    QuickiesAnalyticsEvent.navigationTapOnChats()
                  )
                }
          }
        >
          <ChatBubbleLeftRightIcon height={26} />

          {sessionTotals?.unread_messages ? (
            <UnreadDot isPulsing={isChatNotificationShown} />
          ) : null}
        </ItemLink>
      </Item>

      {hasGroupsEnabled && (
        <Item isActive={isGroupsActive}>
          <ItemLink
            href="/groups"
            aria-label={t(`${i18nKey}.items.groups`)}
            onClick={
              isGroupsPaywalled
                ? handleGroupsPaywalledOnClick
                : () =>
                    void reportEvent(
                      QuickiesAnalyticsEvent.navigationTapOnGroups()
                    )
            }
          >
            <FaUsers size={26} />
          </ItemLink>
        </Item>
      )}

      <Item
        isActive={false}
        role={quickiesSession ? "button" : "presentation"}
        onClick={quickiesSession ? showProfileSheetModal : undefined}
      >
        <QuickiesMemberAvatar
          avatarUrl={
            quickiesSession
              ? quickiesSession.profile.profilePhoto?.thumbnailUrl
              : null
          }
          size={28}
          borderRadius={"8px"}
        />
      </Item>
    </Items>
  )
}

export default NavBar

const Items = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Item = styled.div<{
  isActive: boolean
}>`
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
  z-index: 40;
  background: ${({ theme }) => theme.color.bg.topNavBar};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.tint.default : theme.color.text.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ItemLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  color: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const UnreadDot = styled(OrangeDot)<{ isPulsing: boolean }>`
  width: 14px;
  height: 14px;
  top: 8px;
  right: 8px;
  // background: #5A5A5A;
  border-width: 2px;
  animation: ${({ isPulsing }) =>
    isPulsing ? "unread-dot-pulse 1s infinite" : "none"};
`
