import { useEffect, useState } from "react"
import { useQuickiesSession } from "../../hooks/use-quickies-session"
import { useRouter } from "next/router"
import {
  useSessionDevice,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import styled from "styled-components"
import { UnstyledButtonLink } from "@hornet-web-react/core/components/UI/UnstyledButton"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { getFeedbackMailtoLink } from "../../utils/get-feedback-mailto-link"

const FeedbackButton = () => {
  const router = useRouter()
  const { locale } = useSessionDevice()
  const { data: fullSession } = useQuickiesSession()
  const { isAuthenticated } = useSessionUser()
  const { reportEvent } = useEventTrackerService()

  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [mailtoText, setMailtoText] = useState("")

  useEffect(() => {
    if (fullSession?.account.email) {
      setButtonEnabled(true)
      setMailtoText(getFeedbackMailtoLink(locale, fullSession.account))
    } else {
      setButtonEnabled(false)
    }
  }, [fullSession, locale])

  if (
    !isAuthenticated ||
    router.pathname !== "/map" ||
    !fullSession ||
    !buttonEnabled
  ) {
    return null
  }

  const handleFeedbackOnClick = async () => {
    void reportEvent(
      QuickiesAnalyticsEvent.anywhereTapOnFeedback(router.pathname)
    )
  }

  return (
    <ButtonLink href={mailtoText} onClick={handleFeedbackOnClick}>
      Feedback
    </ButtonLink>
  )
}

export default FeedbackButton

const ButtonLink = styled(UnstyledButtonLink)`
  position: fixed;
  right: -30px;
  top: 50%;
  transform: rotate(-90deg);
  font-size: 14px;
  width: 80px;
  background: rgba(247, 89, 50, 0.7);
  color: ${({ theme }) => theme.color.text.primary};
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
