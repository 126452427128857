import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react"
import { debug } from "@hornet-web-react/core/utils"
import {
  CanAccessAppStore,
  CommunityApiToken,
  Country,
  createDefaultUser,
  DeviceId,
  DeviceLocation,
  InAppVersion,
  PublicAppUrl,
  SessionApi,
  SessionQueryParamsRecord,
  SessionState,
  User,
  LocaleRoute,
} from "@hornet-web-react/core/types/session"
import { AppConfig } from "@hornet-web-react/core/services/AppConfig"
import { removeCookie, setCookie } from "typescript-cookie"
import mergeDeepRight from "ramda/es/mergeDeepRight"
import {
  CookieName,
  PremiumPaywall,
  QuickiesPremiumPaywall,
} from "@hornet-web-react/core/types"
import getCurrentAppUrl from "@hornet-web-react/core/utils/get-current-app-url"
import CSP_NONCE from "@hornet-web-react/core/utils/csp-nonce"
import { setSessionStorageData } from "@hornet-web-react/core/utils/session-storage"
import { UNKNOWN_DEVICE_ID } from "@hornet-web-react/core/utils/constants"
import { getLatLngFromDeviceLocation } from "@hornet-web-react/core/utils/transform-device-location"
import { getLocaleRoute } from "@hornet-web-react/core/utils/get-locale-route"

const SessionEnvironmentContext = createContext<SessionState["environment"]>(
  {} as SessionState["environment"]
)

type SessionDeviceContext = SessionState["device"] & {
  localeRoute: LocaleRoute // for appending locale to the route, does not return default locale
}

const SessionDeviceContext = createContext<SessionDeviceContext>(
  {} as SessionDeviceContext
)
const SessionUserContext = createContext<SessionState["user"]>(
  {} as SessionState["user"]
)
const SessionCommunityContext = createContext<SessionState["community"]>(
  {} as SessionState["community"]
)
const SessionPremiumPaywallContext = createContext<
  SessionState["premiumPaywall"]
>({} as SessionState["premiumPaywall"])

const SessionRouteTransitionContext = createContext<{
  isTransitioning: boolean
  routeHistory: string[]
}>({ isTransitioning: false, routeHistory: [] })

const SessionApiContext = createContext<SessionApi>({} as SessionApi)

type Actions =
  | {
      type: "setPublicAppUrl"
      publicAppUrl: PublicAppUrl
    }
  | {
      type: "setInAppVersion"
      inAppVersion: InAppVersion
    }
  | {
      type: "setSessionQueryParams"
      sessionQueryParamsRecord: SessionQueryParamsRecord
    }
  | {
      type: "setCspNonce"
      cspNonce: string
    }
  | {
      type: "setDeviceId"
      deviceId: DeviceId
    }
  | {
      type: "setCountry"
      country: Country
    }
  | {
      type: "setCanAccessAppStore"
      canAccessAppStore: CanAccessAppStore
    }
  | {
      type: "setDeviceLocation"
      deviceLocation: DeviceLocation
    }
  | {
      type: "setUser"
      user: User
    }
  | {
      type: "setHasPremium"
      hasPremium: boolean
    }
  | {
      type: "setToken"
      communityToken: CommunityApiToken
    }
  | {
      type: "setPremiumPaywall"
      premiumPaywall: PremiumPaywall | QuickiesPremiumPaywall | undefined
    }

const reducer = (state: SessionState, action: Actions): SessionState => {
  debug(`Session reducer action: ${action.type}`)

  switch (action.type) {
    case "setPublicAppUrl":
      return {
        ...state,
        environment: {
          ...state.environment,
          publicAppUrl: action.publicAppUrl,
        },
      }

    case "setInAppVersion":
      return {
        ...state,
        environment: {
          ...state.environment,
          inAppVersion: action.inAppVersion,
        },
      }

    case "setSessionQueryParams":
      return {
        ...state,
        environment: {
          ...state.environment,
          sessionQueryParams: mergeDeepRight(
            state.environment.sessionQueryParams,
            action.sessionQueryParamsRecord
          ),
        },
      }

    case "setCspNonce":
      return {
        ...state,
        environment: { ...state.environment, cspNonce: action.cspNonce },
      }

    case "setDeviceId":
      return {
        ...state,
        device: { ...state.device, deviceId: action.deviceId },
      }

    case "setCountry":
      return { ...state, device: { ...state.device, country: action.country } }

    case "setCanAccessAppStore":
      return {
        ...state,
        device: {
          ...state.device,
          canAccessAppStore: action.canAccessAppStore,
        },
      }

    case "setDeviceLocation":
      // only set if the distance more than some miles or the general status changed
      if (
        shouldUpdateDeviceLocation(
          state.device.deviceLocation,
          action.deviceLocation
        )
      ) {
        return {
          ...state,
          device: { ...state.device, deviceLocation: action.deviceLocation },
        }
      }

      // no-op
      return state

    case "setUser":
      return { ...state, user: action.user }

    case "setHasPremium":
      // we can update only user that exists
      if (state.user.currentUser) {
        return {
          ...state,
          user: {
            ...state.user,
            currentUser: {
              ...state.user.currentUser,
              hasPremium: action.hasPremium,
            },
          },
        }
      }

      return state

    case "setToken":
      return {
        ...state,
        community: {
          ...state.community,
          token: action.communityToken,
        },
      }

    case "setPremiumPaywall":
      return {
        ...state,
        premiumPaywall: action.premiumPaywall,
      }
  }
}

type SessionDataProviderProps = {
  children: ReactNode
  initialData: SessionState
  appConfig: AppConfig
}

export const SessionDataProvider = ({
  children,
  initialData,
  appConfig,
}: SessionDataProviderProps) => {
  // fail without mercy if the initialData is wrong schema
  // this means somewhere we made a mistake in resulting props
  const sessionData = SessionState.parse(
    typeof initialData !== "undefined"
      ? initialData
      : getDefaultInitialData(appConfig) // HACK: Next.js supplies empty pageProps on 404
  )

  // HACK: fix `appUrl` for SSG pages - it needs to be the actual
  // one
  sessionData.environment.appUrl = getCurrentAppUrl(
    appConfig.appUrlProtocol,
    appConfig.appUrl
  )

  const [state, dispatch] = useReducer(reducer, sessionData)

  const [isRouteTransitioning, setIsRouteTransitioning] = useState(false)
  const [routeHistory, setRouteHistory] = useState<string[]>([])

  const sessionApi: SessionApi = useMemo(() => {
    const setPublicAppUrl = (publicAppUrl: PublicAppUrl) => {
      dispatch({ type: "setPublicAppUrl", publicAppUrl })
    }

    const setInAppVersion = (inAppVersion: InAppVersion) => {
      dispatch({ type: "setInAppVersion", inAppVersion })
    }

    const setCspNonce = (cspNonce: string) => {
      dispatch({ type: "setCspNonce", cspNonce })
    }

    const setSessionQueryParams = (
      sessionQueryParams: SessionQueryParamsRecord
    ) => {
      setSessionStorageData(sessionQueryParams)

      dispatch({
        type: "setSessionQueryParams",
        sessionQueryParamsRecord: sessionQueryParams,
      })
    }

    const setDeviceId = (deviceId: DeviceId) => {
      dispatch({ type: "setDeviceId", deviceId })
    }

    const storeDeviceId = (deviceId: DeviceId) => {
      setCookie(CookieName.DeviceId, deviceId, appConfig.simpleCookieConfig())

      dispatch({ type: "setDeviceId", deviceId })
    }

    const setCountry = (country: Country) => {
      dispatch({ type: "setCountry", country })
    }

    const storeCountry = (country: Country) => {
      setCookie(CookieName.Country, country, appConfig.simpleCookieConfig())

      dispatch({ type: "setCountry", country })
    }

    const setCanAccessAppStore = (canAccessAppStore: CanAccessAppStore) => {
      dispatch({ type: "setCanAccessAppStore", canAccessAppStore })
    }

    const setDeviceLocation = (deviceLocation: DeviceLocation) => {
      setCookie(
        CookieName.DeviceLocation,
        deviceLocation,
        appConfig.simpleCookieConfig()
      )

      dispatch({ type: "setDeviceLocation", deviceLocation })
    }

    const setUser = (user: User) => {
      dispatch({ type: "setUser", user })
    }

    const storeUser = (user: User) => {
      removeCookie(CookieName.CommunityToken)

      setCookie(
        CookieName.User,
        JSON.stringify(user),
        appConfig.simpleCookieConfig()
      )

      dispatch({ type: "setUser", user })
    }

    const logout = () => {
      removeCookie(CookieName.CommunityToken)
      storeUser(createDefaultUser())
    }

    const setHasPremium = (hasPremium: boolean) => {
      dispatch({ type: "setHasPremium", hasPremium })
    }

    const setToken = (communityToken: CommunityApiToken) => {
      dispatch({ type: "setToken", communityToken })
    }

    const storeToken = (communityToken: CommunityApiToken) => {
      setCookie(
        CookieName.CommunityToken,
        JSON.stringify(communityToken),
        appConfig.simpleCookieConfig()
      )

      dispatch({ type: "setToken", communityToken })
    }

    const setPremiumPaywall: SessionApi["premium"]["setPremiumPaywall"] = (
      premiumPaywall
    ) => {
      dispatch({ type: "setPremiumPaywall", premiumPaywall })
    }

    return {
      environment: {
        setPublicAppUrl,
        setInAppVersion,
        setSessionQueryParams,
        setCspNonce,
      },
      device: {
        setDeviceId,
        storeDeviceId,
        setCountry,
        storeCountry,
        setCanAccessAppStore,
        setDeviceLocation,
      },
      user: {
        setUser,
        storeUser,
        setHasPremium,
        logout,
      },
      community: {
        setToken,
        storeToken,
      },
      premium: {
        setPremiumPaywall,
      },
      route: {
        setIsRouteTransitioning,
        pushToRouteHistory: (url: string) => {
          setRouteHistory((prev) => [url, ...prev.slice(0, 5)])
        },
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SessionApiContext.Provider value={sessionApi}>
      <SessionEnvironmentContext.Provider value={state.environment}>
        <SessionDeviceContext.Provider
          value={{
            ...state.device,
            localeRoute: getLocaleRoute(
              state.device.locale,
              appConfig.defaultLocale
            ),
          }}
        >
          <SessionUserContext.Provider value={state.user}>
            <SessionCommunityContext.Provider value={state.community}>
              <SessionPremiumPaywallContext.Provider
                value={state.premiumPaywall}
              >
                <SessionRouteTransitionContext.Provider
                  value={{
                    isTransitioning: isRouteTransitioning,
                    routeHistory,
                  }}
                >
                  {children}
                </SessionRouteTransitionContext.Provider>
              </SessionPremiumPaywallContext.Provider>
            </SessionCommunityContext.Provider>
          </SessionUserContext.Provider>
        </SessionDeviceContext.Provider>
      </SessionEnvironmentContext.Provider>
    </SessionApiContext.Provider>
  )
}

export const useSessionApi = () => useContext(SessionApiContext)
export const useSessionEnvironment = () => useContext(SessionEnvironmentContext)
export const useSessionDevice = () => useContext(SessionDeviceContext)
export const useSessionUser = () => useContext(SessionUserContext)
export const useSessionCommunity = () => useContext(SessionCommunityContext)
export const useSessionPremiumPaywall = () =>
  useContext(SessionPremiumPaywallContext)
export const useSessionRouteTransition = () =>
  useContext(SessionRouteTransitionContext)

// TODO: Session Refactor: remove this completely after purging the .stories.*
interface SessionProviderProps {
  children: ReactNode
}

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  return <>{children}</>
}

function getDefaultInitialData(appConfig: AppConfig) {
  return {
    environment: {
      appUrl: appConfig.appUrl,
      appUrlProtocol: appConfig.appUrlProtocol,
      publicAppUrl: appConfig.appUrl,
      isInApp: true, // override for desktop usage
      inAppVersion: "7.11.0", // override for desktop usage (supports WebScreen)
      sessionQueryParams: {},
      cspNonce: CSP_NONCE,
    },
    device: {
      deviceId: UNKNOWN_DEVICE_ID,
      locale: appConfig.defaultLocale,
      country: null,
      canAccessAppStore: true, // optimistic mobile-first approach
      deviceLocation: DeviceLocation.parse(""),
    },
    user: createDefaultUser(),
    community: {
      token: null,
    },
    premiumPaywall: undefined,
  }
}

function shouldUpdateDeviceLocation(
  currentDeviceLocation: DeviceLocation,
  targetDeviceLocation: DeviceLocation
) {
  const currentCoords = getLatLngFromDeviceLocation(currentDeviceLocation)
  const targetCoords = getLatLngFromDeviceLocation(targetDeviceLocation)

  // if both is missing, no need to update
  if (!currentCoords && !targetCoords) {
    return false
  }

  // if any is missing, always update -> is a change
  if (!currentCoords || !targetCoords) {
    return true
  }

  // if both are present, calculate the distance and only update if it's more than 5km
  if (currentCoords && targetCoords) {
    const distance = getDistanceFromLatLon(
      currentCoords.lat,
      currentCoords.lng,
      targetCoords.lat,
      targetCoords.lng
    )

    // HACK: temporarily make this only 1 km/mile, usually it's 5
    // for testing purposes of Project HH
    return distance > 1
  }

  // if only one is present, also update
  return true
}

function getDistanceFromLatLon(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  // TODO: maybe make this work, but it's very low priority
  // whether the user moved 5km or 5miles...
  const isMetricUnitOfMeasure = true
  const earthRadius = isMetricUnitOfMeasure ? 6371 : 3959

  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadius * c
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}
