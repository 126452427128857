const version = require("../public/app-version.js")

const ENV = {
  environment: process.env.NEXT_PUBLIC_APP_ENV || "production",

  version: version,

  appName: "quickies",

  // use for canonical URLs and as base value
  appUrl: "https://hornetx.com",
  appUrlProtocol: "https",

  // for markdown links (to be turned in actual links)
  allowedAppDomains: ["hornetx.com"],

  hornet_web_url: "",

  appStore: {
    ios: {
      appStoreId: "",
      appId: "",
      appName: "",
    },
    android: {
      playStoreId: "",
    },
  },

  auth: {
    facebook: {
      appId: "567246033392315",
      scope: "email",
      version: "v16.0",
      fbxml: false,
    },
    google: {
      clientId:
        "961342034224-6to63nnkhuomgs4tinami7hl2ulpmpvs.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/userinfo.email",
    },
    sso: {
      isMinimal: false,
    },
  },

  aws: {
    cognito: {
      identityPoolId: "us-east-1:f209f4c4-62e3-4c83-9d16-9c25ef57c546",
    },
    region: "us-east-1",
    chatUpload: {
      bucket: "group-chat-raw-photo-messages-production",
    },
  },

  contentSecurityPolicy: `
    base-uri 'self';
    object-src 'none';
    default-src 'self';
    frame-src 'self' *.google.com *.googlesyndication.com *.googletagservices.com *.doubleclick.net hornetx.com quickies.gay;
    script-src 'self' www.googletagmanager.com www.google.com www.gstatic.com connect.facebook.net accounts.google.com www.ipqscdn.com *.doubleclick.net *.googlesyndication.com www.googletagservices.com *.gethornet.com;
    child-src 'self' blob: www.google.com accounts.google.com *.googlesyndication.com;
    style-src 'self' accounts.google.com;
    font-src 'self';
    worker-src 'self' blob:;
    img-src 'self' data: blob: hornet-static.s3.amazonaws.com ghph-development.s3.amazonaws.com akamai.gethornet.com cdn.hnetworkmedia.com www.google-analytics.com hornetapp.com hornetx.com api.mapbox.com *.hornetx.com *.googlesyndication.com *.google.com quickies.gay *.quickies.gay;
    media-src 'self' data: blob: hornet-static.s3.amazonaws.com ghph-development.s3.amazonaws.com akamai.gethornet.com cdn.hnetworkmedia.com hornetx.com;
    connect-src 'self' *.unicornbooty.com *.hornetx.com hornetx.com *.quickies.gay quickies.gay *.google-analytics.com firebase.googleapis.com firebaseinstallations.googleapis.com o289177.ingest.sentry.io api.mapbox.com events.mapbox.com analytics.google.com *.analytics.google.com doubleclick.net *.facebook.com wss://pubsub.staging.gethornet.com:8084/mqtt wss://pubsub.gethornet.com:8084/mqtt wss://pubsub.sqaces.com:8084/mqtt *.ipqscdn.com *.doubleclick.net *.googlesyndication.com *.gstatic.com *.gethornet.com hornetapp.com hornet.com *.authorize.net cognito-identity.us-east-1.amazonaws.com group-chat-raw-photo-messages-production.s3.us-east-1.amazonaws.com;
    report-uri https://o289177.ingest.sentry.io/api/5987776/security/?sentry_key=f76586d1b56b44f5bf56eb971c816182;
    report-to csp-endpoint
  `,

  defaultLocale: "de",
  supportedLocale: [], // not used in this project, see /apps/quickies/locales.js

  eventTracking: {
    url: "https://hornetapp.com/webanalytics/",
    trackerName: "quickies",
  },

  firebase: {
    isEnabled: false,
    isDebug: !["production"].includes(process.env.NEXT_PUBLIC_APP_ENV),
    apps: {
      default: {
        apiKey: "",
        authDomain: "",
        databaseURL: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: "",
      },
      analytics: {},
    },
    logLevel: "silent", // debug | error | silent
    remoteConfigFetchInterval: 2 * 60 * 60 * 1000, // every 5 minutes
  },

  firebaseDynamicLink: {
    baseUrl: "",
  },

  googleAnalytics: {
    isDebug: !["production"].includes(process.env.NEXT_PUBLIC_APP_ENV),

    // Google Analytics 4
    ga4: {
      isEnabled:
        ["production", "alpha", "beta"].includes(
          process.env.NEXT_PUBLIC_APP_ENV
        ) && !process.env.NEXT_PUBLIC_IS_TESTING,

      id: ["production", "beta"].includes(process.env.NEXT_PUBLIC_APP_ENV)
        ? "G-4GX55CWBKD"
        : "G-7RWKJWEQ3J",
    },
  },

  googleRecaptcha: {
    siteKey: "6LdPKhcpAAAAAFpHQitijk_RbJcCkAvzl0GMkqTH",
  },

  googleRecaptchaV3: {
    siteKey: "6LfjLRcpAAAAAA432VQVbep9j7SluMSNzkf5ZRVb",
  },

  ipQualityScore: {
    isEnabled: true,
    hornetScriptSrc:
      "https://www.ipqscdn.com/api/hornetx.com/mP6YtheYnoovwSWtNO4ari1C0ZcvLRRGt6rTV9uMmujsc6FSX65TXeZMYa6YCTTO10kmoFHWQEypwG427cWvRC3eLaG4TUXoloonH8RtjaE4l4fKJwoVV27PBRwoxk9KElkrtj6oJc5g6pPKFvwbWI8AF2TKA8TpeEH0dVngDoI5zkcluy1YmitvSIGIc0PzMSGMSwqbT3yvAw3RSkq3w9mIio4SCbLA0Nv9Z8klcRUXtT4StZHpxFizHqPFvPCp/learn.js",
    hornetAppScriptSrc: "",
  },

  hornet_api: {
    host: "https://api.quickies.gay",
    namespace: "api/v3",
    fallbacks: [],
  },

  hornet_shop_api: {
    host: "https://hornetapp.com/community/wp-json",
    namespace: "wc",
    is_dev: false,
    fallbacks: ["https://hornetappru.com/community/wp-json"],
  },

  community_api: {
    host: "https://hornetapp.com/community/wp-json",
    namespace: "",
    is_dev: false,
    fallbacks: ["https://hornetappru.com/community/wp-json"],
  },

  quickies_api: {
    host: "https://api.quickies.gay",
    namespace: "quickies/api/v3",
    fallbacks: [],
  },

  simpleCookieConfig: (override) => ({
    // domain?: string;
    // encode?(val: string): string;
    expires:
      override?.expires ||
      new Date(new Date().getTime() + 60 * 60 * 24 * 7 * 50 * 1000), // 50 weeks
    httpOnly: false, // we want to access the cookie via JS in client
    path: "/", // root of app
    sameSite: "lax", // `lax` so we can use Payssion cookie redirect
    secure: process.env.NEXT_PUBLIC_APP_ENV !== "development",
  }),

  sentry: {
    isEnabled:
      !process.env.NEXT_PUBLIC_IS_TESTING &&
      !process.env.STORYBOOK &&
      ["production", "beta", "alpha"].includes(process.env.NEXT_PUBLIC_APP_ENV),
    dsn: "https://f76586d1b56b44f5bf56eb971c816182@o289177.ingest.sentry.io/5987776",
    debug: false,
    release: "", // set at EOF
    allowUrls: ["chunk", "https://hornetx.com"],
    tracesSampleRate: ["production", "beta"].includes(
      process.env.NEXT_PUBLIC_APP_ENV
    )
      ? 0.1
      : 0,
    sampleRate: ["production", "beta"].includes(process.env.NEXT_PUBLIC_APP_ENV)
      ? 1
      : 1,
  },

  mapbox: {
    accessToken:
      "pk.eyJ1IjoiaG9ybmV0bmV0d29ya3MiLCJhIjoiY2xwODZyc2JiMmQxZjJ2czRvMjh3ajc1bSJ9.V8ndNUQCbpgykkxxALv9VQ",
    mapboxGlJsVersion: "2.5.1",
    mapboxGlGeocoderVersion: "4.7.2",
  },

  authorizeNet: {
    scriptSrc: "https://js.authorize.net/v1/Accept.js",
    clientKey:
      "49hX9fZHzB8Ck657baphuHh67Pa5QZF64L63c3SB6kd87yT5xLwBAk3cz3NKnYPS",
    apiLoginID: "5Hgyh99EUC",
  },

  payssion: {
    payment_url: "https://www.payssion.com/checkout/live_893f44f96cbbcded",
  },

  payssionEnabledCountries: ["id"],

  defaultPaymentMethod: {},

  mqtt: {
    host: "wss://pubsub.gethornet.com:8084/mqtt",
  },

  publicApiIpCountryKey: "39ef23f6c0722ad98ea76916f30a5605d019f817",
}

ENV.sentry.release = ENV.version

if (["development", "alpha", "beta"].includes(ENV.environment)) {
  ENV.auth.facebook.appId = "567246696725582"

  ENV.googleRecaptcha.siteKey = "6LdHKhcpAAAAAJI3E_55QW10Aw78Qik1Sv5FXNVP"
  ENV.googleRecaptchaV3.siteKey = "6LfTLRcpAAAAALv-OToznWOn9MudJYPpZRKJZwIw"

  // ignored when served via <meta>
  // ENV.contentSecurityPolicy["report-uri"] =
  //   "https://o289177.ingest.sentry.io/api/1526217/security/?sentry_key=ef6ad00455c445439d83af3efadd386d&sentry_environment=ENVIRONMENT&sentry_release=RELEASE;"
  // ENV.contentSecurityPolicy["report-to"] = "sentry_csp_endpoint"

  ENV.contentSecurityPolicy = ENV.contentSecurityPolicy.replace(
    "img-src 'self' data: blob: ",
    "img-src 'self' data: blob: *.picsum.photos picsum.photos "
  )

  // add also staging
  ENV.contentSecurityPolicy = ENV.contentSecurityPolicy.replaceAll(
    "group-chat-raw-photo-messages-production.s3.us-east-1.amazonaws.com",
    "group-chat-raw-photo-messages-production.s3.us-east-1.amazonaws.com group-chat-raw-photo-messages-staging.s3.us-east-1.amazonaws.com"
  )

  ENV.mapbox.accessToken =
    "pk.eyJ1IjoiaG9ybmV0bmV0d29ya3MiLCJhIjoiY2xwODZzd21sMmN4czJobmxtYTZkOXQzcCJ9.B28tSj_-kNyoxcedV9g4kg"
  // add an incremental number to the release so Sentry does not get confused with source maps
  ENV.sentry.release = ENV.version + "-" + process.env.RUN_NUMBER

  ENV.publicApiIpCountryKey = "fe2e0e9d695cb4c07173f00797e146307743b638"
}

if (ENV.environment === "development") {
  ENV.appUrl = "https://localhost:3000"
  ENV.hornet_web_url = "https://localhost:4200"

  ENV.hornet_api.host = "https://alpha.gethornet.com"
  ENV.hornet_api.fallbacks = []
  ENV.quickies_api.host = "https://alpha.gethornet.com"
  ENV.hornet_shop_api.host = "http://localhost:8080"
  ENV.hornet_shop_api.host = "https://fargate-service.unicornbooty.com"
  ENV.hornet_shop_api.is_dev = true
  ENV.community_api.host = "http://localhost:8080"
  ENV.community_api.host = "https://fargate-service.unicornbooty.com"
  ENV.community_api.is_dev = true

  ENV.ipQualityScore.isEnabled = false
  ENV.ipQualityScore.hornetScriptSrc =
    "https://www.ipqscdn.com/api/quickies.alpha.unicornbooty.com/ghNtfTA28h9uvhRcTmuHfNvO4bx8hrEJw5yYpgblCC3744M3MsNVVbVs2GeL0XUojeya7W1P3OMfQ0cqD0UsziwFn4uXafDRtsf9fLhEst2wVBuSgeIL5ltj5ot5lrrSXsl1Q0hMLjhbcvyb3dJ1HqV2uth5V0FsKYOEGTbmIvokSzSIiQ9UA6rVQLJWRpx91HdBDaS787sA88Or8zwjYASMr9qnQYBof9CBYe63IVOkPK0hEXr6Izrk03Y8XdbK/learn.js"

  ENV.authorizeNet = {
    scriptSrc: "https://jstest.authorize.net/v1/Accept.js",
    clientKey:
      "2g646Jf5uPmk4d2RWLshHupH8eMQ46ASx8VZfWL4j9w6ULsCTq7G3YW5h2B44aXM",
    apiLoginID: "4bL89exUU2",
  }

  // comment this out to test it locally with
  // `nx build hornet && nx run hornet:serve:devprod`
  ENV.sentry.isEnabled = false
  delete ENV.sentry.allowUrls
  ENV.sentry.debug = true

  ENV.mqtt.host = "wss://pubsub.staging.gethornet.com:8084/mqtt"

  ENV.aws.cognito.identityPoolId =
    "us-east-1:3efcef4b-1e20-4378-8d38-be8b4160d899"
  ENV.aws.chatUpload.bucket = "group-chat-raw-photo-messages-staging"

  ENV.payssion.payment_url =
    "https://sandbox.payssion.com/checkout/sandbox_ad31a437c894c275"
}

if (ENV.environment === "alpha") {
  ENV.appUrl = "https://quickies.alpha.unicornbooty.com"

  ENV.hornet_api.host = "https://alpha.gethornet.com"
  ENV.quickies_api.host = "https://alpha.gethornet.com"
  ENV.hornet_shop_api.host = "https://fargate-service.unicornbooty.com"
  ENV.hornet_shop_api.is_dev = true
  ENV.community_api.host = "https://fargate-service.unicornbooty.com"
  ENV.community_api.is_dev = true

  ENV.ipQualityScore.hornetScriptSrc =
    "https://www.ipqscdn.com/api/quickies.alpha.unicornbooty.com/ghNtfTA28h9uvhRcTmuHfNvO4bx8hrEJw5yYpgblCC3744M3MsNVVbVs2GeL0XUojeya7W1P3OMfQ0cqD0UsziwFn4uXafDRtsf9fLhEst2wVBuSgeIL5ltj5ot5lrrSXsl1Q0hMLjhbcvyb3dJ1HqV2uth5V0FsKYOEGTbmIvokSzSIiQ9UA6rVQLJWRpx91HdBDaS787sA88Or8zwjYASMr9qnQYBof9CBYe63IVOkPK0hEXr6Izrk03Y8XdbK/learn.js"

  ENV.authorizeNet = {
    scriptSrc: "https://jstest.authorize.net/v1/Accept.js",
    clientKey:
      "2g646Jf5uPmk4d2RWLshHupH8eMQ46ASx8VZfWL4j9w6ULsCTq7G3YW5h2B44aXM",
    apiLoginID: "4bL89exUU2",
  }

  ENV.sentry.allowUrls = ["chunk", "https://quickies.alpha.unicornbooty.com"]

  ENV.mqtt.host = "wss://pubsub.staging.gethornet.com:8084/mqtt"

  ENV.aws.cognito.identityPoolId =
    "us-east-1:3efcef4b-1e20-4378-8d38-be8b4160d899"
  ENV.aws.chatUpload.bucket = "group-chat-raw-photo-messages-staging"

  ENV.payssion.payment_url =
    "https://sandbox.payssion.com/checkout/sandbox_ad31a437c894c275"
}

if (ENV.environment === "beta") {
  ENV.auth.facebook.appId = "567246033392315"

  ENV.appUrl = "https://quickies.beta.unicornbooty.com"

  ENV.hornet_api.host = "https://beta.gethornet.com"
  ENV.quickies_api.host = "https://beta.gethornet.com"

  ENV.googleRecaptcha.siteKey = "6LdTDncUAAAAAIEuqtqJbEi4KHrFbZ4DRepNEmCk"
  ENV.googleRecaptchaV3.siteKey = "6Lf5FsQUAAAAAABE-Av66EaeCpNJio5qc6s5JhtI"

  ENV.sentry.allowUrls = ["chunk", "https://quickies.beta.unicornbooty.com"]
}

// some CI-related hacks
if (process.env.NEXT_PUBLIC_IS_TESTING) {
  // HACK: for lighthouse CI, this is the app url
  ENV.appUrl = "http://localhost:3000"
  ENV.appUrlProtocol = "http"
}

module.exports = ENV
