import appConfig, { ALLOWED_COUNTRY_CODES } from "../app-config"
import { IS_COLOGNE_COOKIE_NAME, IS_COLOGNE_COOKIE_VALUE } from "./is-cologne"
import { IS_CUSTOM_GPS_COOKIE_NAME } from "./is-custom-gps"
import { Country } from "@hornet-web-react/core/types/session"

export function isCountryCodeAllowed(
  countryCode: string,
  getCookie: (cookieName: string) => string | undefined
) {
  // first, the overrides: cookie for sudo, cologne, custom gps, and dev environments
  if (getCookie("is_sudo") === "y") {
    return true
  }

  if (getCookie(IS_COLOGNE_COOKIE_NAME) === IS_COLOGNE_COOKIE_VALUE) {
    return true
  }

  if (getCookie(IS_CUSTOM_GPS_COOKIE_NAME)?.split(",").length === 2) {
    return true
  }

  // wrote it like fn so it's easier to comment/uncomment the Alpha env
  function isOverriddenByEnv() {
    return (
      appConfig.environment === "alpha" ||
      appConfig.environment === "development"
    )
  }

  if (isOverriddenByEnv()) {
    return true
  }

  // then the default: only allow certain countries
  const parsedCountry = Country.safeParse(countryCode.toLowerCase())
  if (!parsedCountry.success) {
    return false
  }

  return (
    parsedCountry.success && ALLOWED_COUNTRY_CODES.includes(parsedCountry.data)
  )
}

export const countryCheckIgnorePathnames = [
  "/uh-oh",
  "/404",
  "/terms-of-service",
  "/privacy-policy",
  "/guidelines",
  "/impressum",
  "/verify/ipq",
  "/unavailable",
  "/debug",
]
