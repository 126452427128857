import { useEffect, useState } from "react"

export const useHasShareCapability = (): boolean => {
  const [hasShareButton, setHasShareButton] = useState(false)

  useEffect(() => {
    setHasShareButton(typeof navigator !== "undefined" && !!navigator.share)
  }, [])

  return hasShareButton
}
