import { useEffect, useState } from "react"
import { SlideModal } from "./Modals"
import {
  useSessionApi,
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import Fieldset from "./Form/Fieldset"
import { COUNTRIES } from "@hornet-web-react/core/utils"
import styled from "styled-components"
import Select from "./Form/Select"
import TextButton, { ButtonLink } from "./UI/TextButton"
import Legend from "./Form/Legend"
import { LOCALES } from "@hornet-web-react/core/utils/locales"
import { useRouter } from "next/router"
import { DEFAULT_USER_COUNTRY } from "@hornet-web-react/core/utils/constants"
import {
  Country,
  PartialSessionState,
} from "@hornet-web-react/core/types/session"
import NiceModal from "@ebay/nice-modal-react"

type DebugViewModalProps = {
  //
}

const DebugViewSelect = styled(Select)`
  margin-bottom: 10px;
  box-shadow: none;

  & .form-select__input {
    color: ${({ theme }) => theme.color.text.primary};
    ${({ theme }) => theme.font.regular.body};
  }
`

const DebugFieldset = styled(Fieldset)`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`

const DebugViewModal = NiceModal.create((props: DebugViewModalProps) => {
  const modal = NiceModal.useModal()
  const environment = useSessionEnvironment()
  const device = useSessionDevice()
  const user = useSessionUser()
  const {
    device: { setCountry, storeCountry },
  } = useSessionApi()

  const [session, setSession] = useState<PartialSessionState>({})
  const [serviceWorkerError, setServiceWorkerError] = useState("")

  useEffect(() => {
    setSession({ environment, device, user })
  }, [environment, device, user])

  const router = useRouter()

  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  const userCountryOptions = [
    { id: "unknown", title: "Unknown" },
    ...COUNTRIES,
  ].map((country) => ({
    optionValue: country.id,
    optionLabel: country.title,
  }))

  const userLocaleOptions = LOCALES.map((locale) => ({
    optionValue: locale,
    optionLabel: locale,
  }))

  const preserveLocation = async () => {
    try {
      storeCountry(device.country || DEFAULT_USER_COUNTRY)
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    }
  }

  const detectLocation = async () => {
    try {
      const response = await fetch(`${environment.appUrl}/api/location`)

      const body = await response.json()

      const detectedCountry = Country.safeParse(body.geo?.country_code)

      if (detectedCountry.success) {
        setCountry(detectedCountry.data)
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    }
  }

  const handleUserLocaleChange = (newLocale: string) => {
    router.push(router.asPath, undefined, { locale: newLocale })
  }

  return (
    <SlideModal
      title="DebugView Modal"
      isOpen={modal.visible}
      closeAction={{
        label: "Close",
        onClick: () => modal.hide(),
      }}
    >
      <DebugFieldset>
        <ButtonLink href={`/${device.locale}/debug`}>
          Go to Debug screen
        </ButtonLink>
      </DebugFieldset>

      <DebugFieldset>
        <Legend>Current Session</Legend>
        <div>
          <code style={{ wordBreak: "break-word" }}>
            {JSON.stringify(session, null, "\t")}
          </code>
        </div>
      </DebugFieldset>

      <DebugFieldset>
        <Legend>Current URL</Legend>
        <div>
          <code style={{ wordBreak: "break-word" }}>{currentUrl}</code>
        </div>
      </DebugFieldset>

      <DebugFieldset>
        <Legend>User Country</Legend>
        <DebugViewSelect
          name="debug_country_change"
          value={device.country || DEFAULT_USER_COUNTRY}
          label="User Country"
          options={userCountryOptions}
          onChange={(value: string) => {
            setCountry(Country.parse(value))
          }}
        />
        <TextButton primary onClick={preserveLocation} size={"small"}>
          Save in Cookie
        </TextButton>{" "}
        <TextButton onClick={detectLocation} size={"small"}>
          Detect by GeoIP
        </TextButton>
      </DebugFieldset>

      <DebugFieldset>
        <Legend>Current Locale</Legend>
        <DebugViewSelect
          name="shop_country_change"
          value={device.locale}
          label="User Locale"
          options={userLocaleOptions}
          onChange={handleUserLocaleChange}
        />
      </DebugFieldset>

      <DebugFieldset>
        <Legend>Service Worker</Legend>
        <div>
          <code style={{ wordBreak: "break-word" }}>
            Available: {"serviceWorker" in navigator ? "yes" : "no"}
            <br />
            Error: {serviceWorkerError}
          </code>
        </div>

        <TextButton
          primary
          onClick={() => {
            if (!("serviceWorker" in navigator)) {
              return
            }

            console.log(`navigator.serviceWorker`, navigator.serviceWorker)

            navigator.serviceWorker.ready
              .then((registration) => {
                return registration.update()
              })
              .catch((err) => {
                setServiceWorkerError(err.message || "Unknown error")
                alert(err)
              })
          }}
          size={"small"}
        >
          Update Registration
        </TextButton>

        <TextButton
          size={"small"}
          onClick={() => {
            if (navigator.serviceWorker.controller) {
              navigator.serviceWorker.controller.postMessage("ping")
            } else {
              alert("No service worker controller")
            }
          }}
        >
          Ping
        </TextButton>

        <TextButton
          size={"small"}
          onClick={() => {
            if (navigator.serviceWorker.controller) {
              navigator.serviceWorker.controller.postMessage(
                "postWindowVisibility"
              )
            } else {
              alert("No service worker controller")
            }
          }}
        >
          Get Window Visibility
        </TextButton>

        <TextButton
          size={"small"}
          onClick={() => {
            navigator.serviceWorker
              .getRegistrations()
              .then(function (registrations) {
                registrations.forEach(function (v) {
                  console.log(v)
                })
              })
          }}
        >
          Get Registrations
        </TextButton>
      </DebugFieldset>
    </SlideModal>
  )
})

export default DebugViewModal
