import { z } from "zod"
import { InviteCode } from "@hornet-web-react/core/types"

export const DickVerificationState = z.enum([
  "none", // null from API
  "pending",
  "accepted",
  "rejected",
  "photos_removed",
])
export type DickVerificationState = z.infer<typeof DickVerificationState>

export const QuickiesSessionFeatureFlag = z.enum(["dick_verification"])
export type QuickiesSessionFeatureFlag = z.infer<
  typeof QuickiesSessionFeatureFlag
>

export const QuickiesPaywallFeatures = z.object({
  profilesOnMap: z.number(),
})
export type QuickiesPaywallFeatures = z.infer<typeof QuickiesPaywallFeatures>

export const QuickiesEntitlementState = z.enum([
  // currently in Germany, free app
  "free",

  // no invites OR invite only+invited, has paywall config
  "freemium",

  // invite only+not invited
  "disabled",

  // anyone with subscription
  "purchased",

  // until the session loads
  "unknown",
])
export type QuickiesEntitlementState = z.infer<typeof QuickiesEntitlementState>

// this is kinda closely following the `QuickiesEntitlementState` with the difference
// that the entitlement comes from session, whereas this landing variant is defined
// without session (most likely via middleware)
export const QuickiesLandingVariant = z.enum([
  // free app, anon login + hornet login
  "ger",

  // landing with login only, checking invites
  "ukr",
  "idn",

  // redirect to /unavailable
  "row",
])
export type QuickiesLandingVariant = z.infer<typeof QuickiesLandingVariant>

// ===== USER REDIRECTS ===== //
type TaggedRedirectState<T extends string> = { tag: T }

export enum UserRedirectFromLoginPayloadEnum {
  Nothing = "nothing",
  Onboarding = "onboarding",
  AcceptInvite = "accept-invite",
  InviteOnly = "invite-only",
  AcceptInviteWithInviteCode = "accept-invite-with-invite-code",
}

export type NothingRedirectState =
  TaggedRedirectState<UserRedirectFromLoginPayloadEnum.Nothing>
export type OnboardingRedirectState =
  TaggedRedirectState<UserRedirectFromLoginPayloadEnum.Onboarding>
export type AcceptInviteRedirectState =
  TaggedRedirectState<UserRedirectFromLoginPayloadEnum.AcceptInvite>
export type InviteOnlyRedirectState =
  TaggedRedirectState<UserRedirectFromLoginPayloadEnum.InviteOnly>
export type AcceptInviteWithInviteCodeRedirectState =
  TaggedRedirectState<UserRedirectFromLoginPayloadEnum.AcceptInviteWithInviteCode> & {
    inviteCode: InviteCode
  }

export type UserRedirectFromLoginPayloadState =
  | NothingRedirectState
  | OnboardingRedirectState
  | AcceptInviteRedirectState
  | InviteOnlyRedirectState
  | AcceptInviteWithInviteCodeRedirectState

// ===== end of USER REDIRECTS ===== //
