import { useEffect, useRef } from "react"
import { usePushNotificationsFlag } from "./use-push-notifications-flag"
import invariant from "tiny-invariant"
import { useApi } from "./use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useRouter } from "next/router"
import { debug } from "@hornet-web-react/core/utils"

type ServiceWorkerProps = {
  hasPushNotifications: boolean
  isEnabled: boolean
}

export const useServiceWorker = (
  config: ServiceWorkerProps,
  isAuthenticated: boolean
) => {
  const isServiceWorkerRegistered = useRef(false)
  const { isEnabled: isNotificationsEnabled } = usePushNotificationsFlag()
  const { makeApiRequest, getEndpoint } = useApi()
  const router = useRouter()

  useEffect(() => {
    if (isServiceWorkerRegistered.current || !config.isEnabled) {
      return
    }

    async function registerPushNotifications(
      registration: ServiceWorkerRegistration
    ) {
      isServiceWorkerRegistered.current = true

      try {
        // console.log(`registration.active`, registration.active)

        if (registration.active) {
          registration.active.onerror = (event) => {
            console.log("useSW: onerror", event)
          }
        }

        // Use the PushManager to get the user's subscription to the push service.
        let subscription = await registration.pushManager.getSubscription()

        // If a subscription was found, return it.
        if (!subscription) {
          // TODO: get the key from ENV
          const vapidPublicKey =
            "BEYP9HR5Ixsc5wnePrWc49b_oYHAHlM8dbDqiqqgknNINEQNN_awGG-NN7dSBSu9sdEQ8XeY9lMAtwYOlkGKFQM"

          // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
          // urlBase64ToUint8Array() is defined in /tools.js
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)

          // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
          // send notifications that don't have a visible effect for the user).
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
          })
        }

        const subJson = subscription.toJSON()
        // console.log(`subJson`, subJson)
        const { keys, endpoint } = subJson

        // Send the subscription details to the server using the Fetch API.
        invariant(keys && endpoint, "keys and endpoint are required")

        await makeApiRequest(
          getEndpoint(ApiServiceEndpoint.PushNotificationsPost),
          {
            endpoint,
            p256dh: keys["p256dh"],
            auth: keys["auth"],
          }
        )
      } catch (err) {
        console.error(`useSW: push notif registration error`, err)
      }
    }

    async function registerServiceWorker() {
      try {
        const registration = await navigator.serviceWorker.register("/sw.js")

        navigator.serviceWorker.onmessage = (event) => {
          console.log(`useSW: onmessage`, event)
        }

        navigator.serviceWorker.onmessageerror = (event) => {
          console.log(`useSW: onmessageerror`, event)
        }

        // console.log(`config.hasPushNotifications`, config.hasPushNotifications)
        // console.log(`isEnabled`, isEnabled)
        // console.log(`isAuthenticated`, isAuthenticated)
        if (
          config.hasPushNotifications &&
          isNotificationsEnabled &&
          isAuthenticated
        ) {
          void registerPushNotifications(registration)
        }
      } catch (error) {
        console.error(`useSW: registration error`, error)
      }
    }

    // sub to push notifications
    if ("serviceWorker" in navigator) {
      void registerServiceWorker()
    }
  }, [
    config,
    isNotificationsEnabled,
    isAuthenticated,
    makeApiRequest,
    getEndpoint,
  ])

  useEffect(() => {
    if (!("serviceWorker" in navigator) || !config.isEnabled) {
      return
    }

    // update the service worker when user foregrounds the app
    const onVisibilityChange = () => {
      // console.log(`document.visibilityState`, document.visibilityState)
      if (document.visibilityState === "visible") {
        navigator.serviceWorker.ready
          .then((registration) => {
            return registration.update()
          })
          .catch((err) => {
            console.error(`service worker update error`, err)
          })
      }
    }
    document.addEventListener("visibilitychange", onVisibilityChange)

    // it's not used on the client and it's not supported in some browsers like iOS 15.2.1
    // https://hornet-networks-ltd.sentry.io/issues/5210396564
    //     const consoleChannel = new BroadcastChannel("consoleMessages")
    const handleIncomingServiceWorkerMessage = (
      event: MessageEvent<unknown>
    ) => {
      debug(["useSW: console", JSON.stringify(event.data)])

      // continue only if there could be any instructions with {action: string} object
      if (
        typeof event.data !== "object" ||
        !event.data ||
        !("action" in event.data)
      ) {
        return
      }

      // the `client.navigate()` doesn't seem to work so trying this redirect workaround
      // expected format:
      //  * { action: "/map" }
      //  * { action: "/inbox" }
      //  * { action: "/groups/:id" }
      void router.push(event.data["action"] as string)
    }

    // consoleChannel.addEventListener(
    //   "message",
    //   handleIncomingServiceWorkerMessage
    // )
    navigator.serviceWorker.addEventListener(
      "message",
      handleIncomingServiceWorkerMessage
    )

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange)
      // consoleChannel.removeEventListener(
      //   "message",
      //   handleIncomingServiceWorkerMessage
      // )
      navigator.serviceWorker.removeEventListener(
        "message",
        handleIncomingServiceWorkerMessage
      )
    }
  }, [config.isEnabled, router])
}

// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
