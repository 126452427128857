import { getCookie } from "typescript-cookie"
import { GpsCoords } from "@hornet-web-react/core/types/session"

export const IS_COLOGNE_COOKIE_NAME = "is_cologne"
export const IS_COLOGNE_COOKIE_VALUE = "y"

export function isCologne() {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    return (
      getCookie(IS_COLOGNE_COOKIE_NAME) === IS_COLOGNE_COOKIE_VALUE || false
    )
  }

  return false
}

export const DEFAULT_COLOGNE_LOCATION = {
  lat: 50.938361,
  lng: 6.959974,
}

export const MY_LOCATION_IN_COLOGNE = getRandomCoordinates()

export function isCoordsCologne(coords: GpsCoords) {
  return (
    (coords.lat === DEFAULT_COLOGNE_LOCATION.lat &&
      coords.lng === DEFAULT_COLOGNE_LOCATION.lng) ||
    (coords.lat === MY_LOCATION_IN_COLOGNE.lat &&
      coords.lng === MY_LOCATION_IN_COLOGNE.lng)
  )
}

function getRandomCoordinates() {
  const radiusInDegrees = 20 / 111.32 // Roughly 111.32km in 1 degree
  const cologneCityCenter = DEFAULT_COLOGNE_LOCATION

  const w = radiusInDegrees * Math.sqrt(Math.random())
  const t = 2 * Math.PI * Math.random()
  const dx = w * Math.cos(t)
  const dy = w * Math.sin(t)

  // Adjust the longitude for smaller circles, as they get smaller towards the poles
  const adjustedDx = dx / Math.cos((cologneCityCenter.lat * Math.PI) / 180)

  const newLat = cologneCityCenter.lat + dy
  const newLng = cologneCityCenter.lng + adjustedDx

  return { lat: newLat, lng: newLng }
}
