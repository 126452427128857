import Head from "next/head"
import { useRouter } from "next/router"
import TopNavBar from "./TopNavBar"
import AppContainer from "./AppContainer"
import appConfig from "../../app-config"
import {
  useSessionEnvironment,
  useSessionDevice,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import useTranslation from "next-translate/useTranslation"
import { PropsWithChildren, ReactNode } from "react"
import BottomNavBar from "./BottomNavBar"
import Footer from "./Footer"
import Spinner from "@hornet-web-react/core/components/UI/Spinner"
import styled from "styled-components"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { useQuickiesLogout } from "../../hooks/use-quickies-logout"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { getLocaleRoute } from "@hornet-web-react/core/utils/get-locale-route"
import { getDefaultMeta } from "../../utils/get-default-meta"
import { Locale } from "@hornet-web-react/core/types/session"

type LayoutProps = PropsWithChildren & {
  title: string
  meta?: Record<
    string,
    {
      name: string
      content?: string
      property?: string
    }
  >
  showTopNavBar?: boolean
  showBottomNavBar?: boolean
  showFooter?: boolean
  hideTopNavBarMenu?: boolean
  canonicalUrl?: string | null
  utmCampaign?: string
  topNavBarRightCornerElement?: ReactNode
  hasLogoutButton?: boolean
}

function Layout({
  children,
  title,
  topNavBarRightCornerElement,
  canonicalUrl = null,
  showTopNavBar = true,
  showBottomNavBar = true,
  showFooter = false,
  hideTopNavBarMenu = false,
  utmCampaign = "index",
  hasLogoutButton = false,
}: LayoutProps) {
  const router = useRouter()
  const { isInApp } = useSessionEnvironment()
  const { isAuthenticated } = useSessionUser()
  const { locale } = useSessionDevice()
  const { t } = useTranslation()
  const { logoutAction, isLoggingOut } = useQuickiesLogout()
  const { reportEvent } = useEventTrackerService()

  // default stuff same for every page
  const { metaTitle, metaDescription } = getDefaultMeta(t, locale)

  // default title
  title = title
    ? `${title} | ${t("quickies:page_title.app")}`
    : t("quickies:page_title.index")

  // correct usage of App URl -> absolute, immutable `appConfig.appUrl`
  // for canonical - even if user accesses from different domain, there shall
  // be only a single canonical URL domain
  // also include locale, so there's no redirects or fetches from bots with
  // different locales (eg hornetx.com/uk -> Facebook bot fetches hornetx.com
  // if that's the canonical)
  const localeRoute = getLocaleRoute(locale, appConfig.defaultLocale)

  // strip any query params from the canonical URL - we don't use them to change
  // the content
  let routerAsPath = router.asPath
  routerAsPath = routerAsPath.split("?")[0]

  canonicalUrl =
    canonicalUrl ??
    `${appConfig.appUrl}${localeRoute}${
      routerAsPath !== "/" ? routerAsPath : ""
    }`

  showTopNavBar = !isInApp && showTopNavBar
  showBottomNavBar = !isInApp && showBottomNavBar

  if (
    topNavBarRightCornerElement === undefined &&
    hasLogoutButton &&
    isAuthenticated
  ) {
    topNavBarRightCornerElement = (
      <LogoutButton
        type={"button"}
        onClick={() => {
          void reportEvent(
            QuickiesAnalyticsEvent.navigationTapOnLogout(router.pathname)
          )

          void logoutAction()
        }}
        disabled={isLoggingOut}
      >
        {isLoggingOut ? (
          <Spinner size={"auto"} />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
          >
            <g clipPath="url(#a)">
              <path
                fill="#fff"
                d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5ZM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5Z"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
              </clipPath>
            </defs>
          </svg>
        )}
      </LogoutButton>
    )
  }

  let socialMediaThumbImageUrl =
    appConfig.appUrl + "/assets/images/social-media-thumb.jpg"
  if (locale === Locale.enum.id) {
    socialMediaThumbImageUrl =
      appConfig.appUrl + "/assets/images/social-media-thumb_id.jpg"
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />

        <meta
          name={"viewport"}
          content={"viewport-fit=cover, width=device-width, initial-scale=1"}
        />
        <meta name={"description"} content={metaDescription} />
        <meta name={"og:description"} content={metaDescription} />
        <meta name={"og:image"} content={socialMediaThumbImageUrl} />
        <meta name={"og:image:width"} content={String(1280)} />
        <meta name={"og:image:height"} content={String(720)} />
        <meta name={"og:type"} content={"website"} />
        <meta name={"og:title"} content={metaTitle} />
        <meta name={"twitter:title"} content={metaTitle} />
        <meta name={"twitter:description"} content={metaDescription} />
        <meta name={"twitter:image"} content={socialMediaThumbImageUrl} />
        <meta name={"robots"} content={"index, follow"} />
        <meta name={"og:url"} content={canonicalUrl} />
        <meta name={"twitter:card"} content={"summary_large_image"} />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="HornetX" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />

        <style>
          {`
            html,
            body {
              background-color: #000000;
            }
          `}
        </style>
      </Head>

      {showTopNavBar && (
        <TopNavBar
          hideNavBarMenu={hideTopNavBarMenu}
          rightCornerElement={topNavBarRightCornerElement}
        />
      )}

      <AppContainer
        hasTopNavBar={showTopNavBar}
        hasBottomNavBar={showBottomNavBar}
      >
        {children}
      </AppContainer>

      {showBottomNavBar && <BottomNavBar utmCampaign={utmCampaign} />}

      <Footer />
    </>
  )
}

export default Layout

const LogoutButton = styled(IconButton)``
