import { useCoreService } from "@hornet-web-react/core/contexts/services"
import WebsocketService, {
  MessageListenerCallback,
  WebsocketServiceContext,
} from "@hornet-web-react/core/services/WebsocketService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useCallback, useRef } from "react"
import {
  RealtimeSessionTotalsApiPayload,
  RealtimeSessionValidationApiPayload,
} from "@hornet-web-react/core/models/realtime-session.model"
import { useSessionTotals } from "@hornet-web-react/core/hooks/use-session-totals"
import { debug } from "@hornet-web-react/core/utils"
import { IncomingRealtimeMessageApiPayload } from "@hornet-web-react/chat/models/realtime-message.model"
import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import { getConversationMessageBodyPreview } from "@hornet-web-react/chat/components/Inbox/ConversationMessageBodyPreview"
import { BareMessageModel } from "@hornet-web-react/chat/models/messages-conversation.model"
import { useChatNotificationsApi } from "../contexts/chat-notifications-context"
import { useQuickiesSession } from "./use-quickies-session"
import { z } from "zod"
import { reloadQuickiesProfile } from "./use-quickies-member"
import { DickVerificationState } from "../models/types"

const i18nKey = "quickies:hooks.use_realtime_session"

type UseQuickiesRealtimeSessionProps = {
  markAccountAsVerified: (isVerified: boolean) => void
}

const RealtimeSessionDickVerificationStateChangeApiPayload = z.object({
  data: z.object({
    state: DickVerificationState,
  }),
})
type RealtimeSessionDickVerificationStateChangeApiPayload = z.infer<
  typeof RealtimeSessionDickVerificationStateChangeApiPayload
>

export const useQuickiesRealtimeSession = ({
  markAccountAsVerified,
}: UseQuickiesRealtimeSessionProps) => {
  const { t } = useTranslation()
  const { data: quickiesSession, mutate: mutateSession } = useQuickiesSession()
  const websocketService = useCoreService<WebsocketService>(
    CORE_TYPES.WebsocketService
  )
  const router = useRouter()

  const { updateWithRealtimeData, increaseUnreadCount } = useSessionTotals()
  const { showChatNotification } = useChatNotificationsApi()

  const sessionTotalsListener = (payload: RealtimeSessionTotalsApiPayload) => {
    const messagePayload = RealtimeSessionTotalsApiPayload.parse(payload)

    updateWithRealtimeData(messagePayload)
  }

  const sessionValidationListener = (
    payload: RealtimeSessionValidationApiPayload
  ) => {
    const messagePayload = RealtimeSessionValidationApiPayload.parse(payload)

    markAccountAsVerified(messagePayload.data.sending_disabled === false)
  }

  const incomingChatMessageListener = (
    message: IncomingRealtimeMessageApiPayload,
    context: WebsocketServiceContext
  ) => {
    const messagePayload = IncomingRealtimeMessageApiPayload.parse(message)

    // this handler should only increase the unread count and fire off the
    // browser notification if that conversation is not already opened

    // a bit of hack here to check actually router path
    const conversationId = messagePayload.data.message.sender
    if (
      router.pathname === "/inbox/[...inbox]" &&
      router.asPath.includes(conversationId)
    ) {
      // skip as user is already in the conversation and does not need notifications
      return
    }

    // bump the unread messages count
    increaseUnreadCount()

    const notificationBody = getConversationMessageBodyPreview(
      new BareMessageModel(
        message.data.message,
        context.profileId ?? undefined
      ),
      t
    )

    // we use push notifications instead so let's ignore this
    // sendNotification(
    //   t(`${i18nKey}.chat_notification.title`),
    //   notificationBody,
    //   `chat:${conversationId}`
    // )

    // show custom chat message notification
    showChatNotification(notificationBody, conversationId)
  }

  const incomingDickVerificationStateChangeListener = (
    payload: RealtimeSessionDickVerificationStateChangeApiPayload
  ) => {
    // not needed
    // const messagePayload =
    //   RealtimeSessionDickVerificationStateChangeApiPayload.parse(payload)

    // refresh session
    void mutateSession()

    // mutate the member profile
    if (quickiesSession?.profile.profileId) {
      void reloadQuickiesProfile(quickiesSession.profile.profileId)
    }
  }

  const handleSessionTotals = useRef<
    MessageListenerCallback<RealtimeSessionTotalsApiPayload>
  >(sessionTotalsListener)
  handleSessionTotals.current = sessionTotalsListener

  const handleSessionValidation = useRef<
    MessageListenerCallback<RealtimeSessionValidationApiPayload>
  >(sessionValidationListener)
  handleSessionValidation.current = sessionValidationListener

  const handleIncomingChatMessage = useRef<
    MessageListenerCallback<IncomingRealtimeMessageApiPayload>
  >(incomingChatMessageListener)
  handleIncomingChatMessage.current = incomingChatMessageListener

  const handleSessionDickVerificationStateChange = useRef<
    MessageListenerCallback<RealtimeSessionDickVerificationStateChangeApiPayload>
  >(incomingDickVerificationStateChangeListener)
  handleSessionDickVerificationStateChange.current =
    incomingDickVerificationStateChangeListener

  const subscribe = useCallback(() => {
    debug("useRealtimeSession.subscribe")

    // docs:
    // https://github.com/hornet-network/hornet_docs/blob/7bec8f16721294fc6097bc8394d835f8d8ecabf4/PubSub/Topics.md#userprofile_idsessiontotals
    websocketService.addMessageListener<RealtimeSessionTotalsApiPayload>(
      "session:handleSessionTotals",
      "user/{profileId}/session/totals",
      handleSessionTotals
    )

    // docs:
    // https://github.com/hornet-network/hornet_docs/blob/7bec8f16721294fc6097bc8394d835f8d8ecabf4/PubSub/Topics.md#session-totals-updated---primary_inbox_dot-userprofile_idsessionprimary_inbox_dot
    websocketService.addMessageListener<RealtimeSessionTotalsApiPayload>(
      "session:handleSessionPrimaryInboxDot",
      "user/{profileId}/session/primary_inbox_dot",
      handleSessionTotals
    )

    // docs:
    // https://github.com/hornet-network/hornet_docs/blob/7bec8f16721294fc6097bc8394d835f8d8ecabf4/PubSub/Topics.md#session-totals-updated---requests_inbox_dot-userprofile_idsessionrequests_inbox_dot
    websocketService.addMessageListener<RealtimeSessionTotalsApiPayload>(
      "session:handleSessionRequestsInboxDot",
      "user/{profileId}/session/requests_inbox_dot",
      handleSessionTotals
    )

    // docs:
    // https://github.com/hornet-network/hornet_docs/blob/7bec8f16721294fc6097bc8394d835f8d8ecabf4/PubSub/Topics.md#userprofile_idsessionvalidation
    websocketService.addMessageListener<RealtimeSessionValidationApiPayload>(
      "session:handleSessionValidation",
      "user/{profileId}/session/validation",
      handleSessionValidation
    )

    // docs:
    // https://github.com/hornet-network/hornet_docs/blob/7bec8f16721294fc6097bc8394d835f8d8ecabf4/PubSub/Message.md#userprofile_idchatmessage
    websocketService.addMessageListener<IncomingRealtimeMessageApiPayload>(
      "session:handleIncomingChatMessage",
      "user/{profileId}/chat/message",
      handleIncomingChatMessage
    )

    websocketService.addMessageListener<RealtimeSessionDickVerificationStateChangeApiPayload>(
      "session:handleSessionDickVerificationStateChange",
      "user/{profileId}/session/quickies_dick_verification_state_change",
      handleSessionDickVerificationStateChange
    )
  }, [websocketService])

  const unsubscribe = useCallback(() => {
    debug("useRealtimeSession.unsubscribe")

    websocketService.removeMessageListener("session:handleSessionTotals")
    websocketService.removeMessageListener(
      "session:handleSessionPrimaryInboxDot"
    )
    websocketService.removeMessageListener(
      "session:handleSessionRequestsInboxDot"
    )
    websocketService.removeMessageListener("session:handleSessionValidation")
    websocketService.removeMessageListener("session:handleIncomingChatMessage")
    websocketService.removeMessageListener(
      "session:handleSessionDickVerificationStateChange"
    )
  }, [websocketService])

  return { subscribe, unsubscribe }
}
