let locales = ["de", "de-DE", "uk", "uk-UA", "en", "id"]

if (
  process.env.NEXT_PUBLIC_APP_ENV === "development" ||
  process.env.NEXT_PUBLIC_APP_ENV === "alpha"
) {
  locales = ["tr", "cs-CZ", ...locales]
}

module.exports = locales
